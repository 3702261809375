import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const Blog = ({ blog }) => {
  if (Object.keys(blog).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <div className='card-wrapper'>
      <div className='card'>
        {blog.imageUrl.length > 1 ? (
          <img
            src={blog.imageUrl}
            style={imageStyles}
            className='blog-card-image'
            alt={blog.title}
          />
        ) : (
          <i className='fas fa-user'></i>
        )}
        <div>
          <h2 className='card-title'>{blog.title}</h2>
          <small className='card-subtitle'>{blog.subtitle} </small>
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(blog.createdAt).format('ll')}{' '}
          </small>
          <div>
            <p>{blog.description && blog.description.slice(0, 250)} ...</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Blog.propTypes = {}

export default Blog
