import React, { useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import SignIn from '../auth/SignIn'

const UserActivation = (props) => {
  const id = props.match.params.id
  useEffect(() => {
    console.log(id)
    const activateEmail = () => {
      axios
        .post('/api/users/user/activation/' + id)
        .then((res) => {
          console.log(res)
        })
        .catch((err) => console.log(err))
    }
    activateEmail()
  }, [])

  return (
    <SignIn title='You account has been activated, sign in to your account' />
  )
}

UserActivation.propTypes = {}

export default UserActivation
