import React from 'react'
import { NavLink } from 'react-router-dom'
import Support from './Support'
import Spinner from '../Spinner'
import Layout from '../Layout'
import { connect } from 'react-redux'

const Supports = (props) => {
  let renderContent
  let filteredSupports
  if (props.auth.isAuthenticated) {
    filteredSupports = props.supports.filter(
      (sup) => sup.public || sup.public === false
    )
  } else {
    filteredSupports = props.supports.filter((sup) => sup.public)
  }

  if (props.supports.length) {
    renderContent = filteredSupports.map((support, i) => (
      <div
        key={support._id}
        className='cards'
      >
        <NavLink to={`/supports/${support._id}`}>
          <Support support={support} />
        </NavLink>
      </div>
    ))
  } else if (props.supports.length === 0) {
    renderContent = <h1>No support available</h1>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Supports: ' + filteredSupports.length}>
      <div className='main-wrapper'>
        <div className='cards'>{renderContent}</div>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(Supports)
