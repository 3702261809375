import React from 'react'
import Layout from '../Layout'

const ActivationEmailMessage = (props) => {
  return (
    <Layout title='Activation Link'>
      <div className='user-activation-message signup-form-wrapper'>
        <p>An activation link has been sent to your email</p>
      </div>
    </Layout>
  )
}

export default ActivationEmailMessage
