import React from 'react'
import Layout from './Layout'

const About = (props) => (
  <Layout title='About Us'>
    <div className='about'>
      <h1 className='about-title'>Introduction</h1>
      <section>
        <p>
          Amhara Association of Finland(AAF) was founded in 30 December 2019.
          Its main domicile is located in Helsinki, Finland(AAF).
          <br />
          AAF is a non-for-profit civic organization governed by Finnish Act No.
          503 of May 1989, as amended by other acts at different times. The rule
          of these acts are applicable to the Amhara Association, without major
          prejudice to it.
          <br />
          The Association, mobilizes financial resources from donors, or members
          to support humanitarian, peaceful, development activities here in
          Finland and back home, that helps fulfil its objectives. It is a
          non-discriminatory on tribal, religious, sexual-orientation, gender or
          political grounds. Members are accepted as long as they ascribe to the
          rules, objectives and missions of the association and fulfil their
          membership duties and obligations.
        </p>
      </section>
      <section>
        <h1 className='about-title'>Vision</h1>
        <article id='introduction'>
          <p>
            The association wants to see a well-protected, united, healthier,
            lawful, prosperous Amhara Community that is well integrated into the
            Finnish society, and at the same time, that plays a pivotal role in
            socio-economic development in origin country; . Idealizes an
            original-home that has a safer environment, better access to clean
            water, education, health services for its citizens (that need to be
            free from hunger and malnutrition, as well).
          </p>
          <ul className='ul-list'>
            <li className='list'>
              {' '}
              Creating a platform (association) that aggregates resources
              (material, financial, knowledge, spiritual, and others), for the
              benefit of its members or for Amhara people back-home, as well as
              for the host-society in Finland.
            </li>
            <li className='list'>
              {' '}
              Up-holding Finnish values, and also enriching local multicultural
              settings, by maintaining cultural, historical, ties with original
              home and bringing good cultural knowledge to host space;
            </li>
            <li className='list'>
              {' '}
              Engaging in humanitarian, development, scientific-knowledge
              transfer, and helping Amhara-society back home;
            </li>
            <li className='list'>
              {' '}
              Helping the Finish government and civil societies by partnering in
              transferring required local knowledge about Ethiopia and Amhara
              situations;
            </li>
            <li className='list'>
              {' '}
              Keep exemplary civil culture, engage in recreational, sports and
              other activities that empower our community help cultural exchange
              between Finland and Amhara society as well; and other strategies
              that are acceptable according to the standards of the laws of
              Finland.
            </li>
            <li className='list'>
              {' '}
              Acts as a catalyst for tourism, investment, and people to people
              exchange between Amhara and Finnish society.
            </li>
          </ul>
        </article>
      </section>
      <section>
        <h1 className='about-title'>Mission</h1>
        <article id='about-express'>
          <p>
            Building an educated, active, socially versatile, competent Amhara
            Generations that could inherit all the Goods of their ancestors here
            in Finland and back home. <br />
            Strives to see the end of genocide against ‘Amhara-Speaking
            Ethiopians’, and visualizes a more peaceful, democratically and
            scientifically advanced, humanely-developed Ethiopia that guarantees
            civil liberties for all in its territories;
          </p>
        </article>
      </section>
    </div>
  </Layout>
)

export default About
