import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
// import galleryImage from '../../assets/images/gallery.jpg'
import galleryImage from '../../assets/images/gallery_2.png'
import { connect } from 'react-redux'
import BlogStat from '../shared/BlogStat'
import SocialShareButton from '../shared/SocialShareButton'

const Details = ({
  image,
  auth,
  deleteImage,
  previousImage,
  nextImage,
  id,
  index,
  length,
}) => {
  const { isAuthenticated, user } = auth
  const userId = user.id
  if (image == null) {
    return <Spinner />
  }

  return (
    <>
      <div className='gallery-image-full-size'>
        {' '}
        <div className='gallery-arrow'>
          {index > 0 && (
            <i
              className='fas fa-chevron-circle-left'
              onClick={previousImage}
            ></i>
          )}
        </div>
        {image.imageUrl && image.imageUrl.length > 1 ? (
          <div className='single-img-full'>
            <img
              src={image.imageUrl}
              className=''
              alt={image.title}
            />
          </div>
        ) : (
          <div className='single-img-full'>
            <img
              src={galleryImage}
              className=''
              alt={image.title}
            />{' '}
          </div>
        )}
        <div className='gallery-arrow'>
          {' '}
          {index + 1 !== length && (
            <i
              className='fas fa-chevron-circle-right'
              onClick={nextImage}
            ></i>
          )}
        </div>
      </div>

      <section className='gallery-text-section'>
        <div
          className='card-category'
          style={{ textAlign: 'center' }}
        >
          <BlogStat item={image} />
          {/* <h4 className='card-title'>{image.title}</h4>
          <small className='card-subtitle'>{image.subtitle}</small>
          <small className='card-date'>
            Published on <i className='far fa-clock'></i>{' '}
            {moment(image.createdAt).format('ll')}
          </small>
          <Status approved={image.approved} /> */}
        </div>
        <div className='card-body'>
          <div className='card-category'>
            <p className='description'>
              {<ReactMarkdown children={image.description} />}
            </p>
          </div>
        </div>
      </section>

      <div className='card-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Posted on{' '}
            {moment(image.createdAt).format('ll')}{' '}
          </small>
        </div>

        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton
            category={'images'}
            id={image._id}
          />
        </div>
        {isAuthenticated && (
          <div className='icons'>
            <NavLink
              to={`/images/edit/${image._id}`}
              className='btn-primary'
            >
              {' '}
              <i className='far fa-edit'></i>
            </NavLink>

            <i
              className='btn-danger far fa-trash-alt'
              onClick={deleteImage}
            ></i>
          </div>
        )}
      </div>
    </>
  )
}

const ImageDetail = (props) => {
  const id = props.match.params.id
  const initialIndex = props.images.findIndex((st, i) => st._id == id)
  const [index, setIndex] = useState(initialIndex)
  const [data, setData] = useState(props.images[initialIndex])

  useEffect(() => {}, [props.match.params.id])
  const { auth } = props

  const deleteImage = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/images/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/images')
  }

  const approveImage = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/images/approve/${id}`
    axios
      .put(url)
      .then((response) => {})
      .catch((err) => {})
  }

  const nextImage = () => {
    if (index < props.images.length - 1) {
      setIndex(index + 1)
      const data = props.images[index + 1]
      setData(data)
      props.history.push(`/images/${data._id}`)
    }
  }

  const previousImage = () => {
    if (index < props.images.length && index !== 0) {
      let previousIndex = index - 1
      setIndex(previousIndex)
      const data = props.images[previousIndex]
      setData(data)
      props.history.push(`/images/${data._id}`)
    }
  }

  let renderContent
  let item

  if (props.images.length > 0) {
    renderContent = (
      <Details
        image={data}
        auth={auth}
        deleteImage={deleteImage}
        nextImage={nextImage}
        previousImage={previousImage}
        length={props.images.length}
        index={index}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout
      title={data && data.title}
      imageUrl={data && data.imageUrl}
    >
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

ImageDetail.propTypes = {}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(ImageDetail)
