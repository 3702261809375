import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children, title }) => (
  <>
    <Header title={title} />
    <main>
      <div className='main-wrapper'>{children}</div>
    </main>
    <Footer />
  </>
)

Layout.propTypes = {
  title: PropTypes.string,
}

export default Layout
