import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// import galleryImage from '../../assets/images/gallery.jpg'
import galleryImage from '../../assets/images/gallery_2.png'

const Image = ({ image, index }) => {
  if (Object.keys(image).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  // const cardStyles = {
  //   display: 'flex',
  //   justifyContent: 'space-around',
  //   alignItems: 'center',
  // }

  return (
    <>
      <div className='gallery__item'>
        {image.imageUrl.length > 1 ? (
          <img
            src={image.imageUrl}
            style={imageStyles}
            className='gallery__img'
            alt={image.title}
          />
        ) : (
          <img
            src={galleryImage}
            style={imageStyles}
            className='gallery__img'
            alt={image.title}
          />
        )}
        {/* <div>
            <h2 className='card-title'>{image.title}</h2>
            <small className='card-subtitle'>{image.subtitle} </small>
            <small className='card-date'>
              Published on <i className='far fa-clock'></i>{' '}
              {moment(image.createdAt).format('ll')}{' '}
            </small>
            <Status approved={image.approved} />
            <div>
              <p>{image.description && image.description.slice(0, 250)} ...</p>
            </div>
          </div> */}
      </div>
    </>
  )
}

Image.propTypes = {}

export default Image
