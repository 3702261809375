import React from 'react'
import BlogStat from '../shared/BlogStat'
import PropTypes from 'prop-types'

const Event = (props) => {
  if (Object.keys(props.event).length < 1) {
    return <h1>Loading...</h1>
  }

  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <div className='card-wrapper'>
        <div className='card'>
          {props.event.imageUrl.length > 1 ? (
            <img
              src={props.event.imageUrl}
              style={imageStyles}
              loading='lazy'
              className='card-image'
              alt={props.event.title}
            />
          ) : (
            <i className='fas fa-user'></i>
          )}
          <div>
            <BlogStat item={props.event} />
            <div>
              <p>
                {props.event.description &&
                  props.event.description.slice(0, 150)}{' '}
                ...
              </p>
            </div>
          </div>
        </div>
    </div>
  )
}

Event.propTypes = {}

export default Event
