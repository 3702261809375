import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import TextInputField from '../shared/TextInputField'
import { connect } from 'react-redux'
import { signUp } from '../../store/actions/authActions'
import Layout from '../Layout'

class SignUp extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    errors: {},
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      })
    }
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/signin')
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    const { firstName, lastName, email, password, password2 } = this.state
    const data = { firstName, lastName, email, password, password2 }
    this.props.signUp(data, this.props.history)
  }
  render() {
    const { errors } = this.state
    return (
      <Layout title='Sign Up'>
        <div className='auth-outer'>
          <div className='signup-form-wrapper'>
            <div>
              <h2 className='auth-title'>Create your account</h2>
            </div>

            <form onSubmit={this.handleSubmit}>
              <TextInputField
                name='firstName'
                placeholder='First Name'
                value={this.state.firstName}
                onChange={this.handleChange}
                error={errors.firstName}
              />
              <TextInputField
                name='lastName'
                placeholder='Last Name'
                value={this.state.lastName}
                onChange={this.handleChange}
                error={errors.lastName}
              />
              {/* <TextInputField
                name='username'
                placeholder='Username'
                value={this.state.username}
                onChange={this.handleChange}
                error={errors.username}
              /> */}
              <TextInputField
                name='email'
                type='email'
                placeholder='Email'
                value={this.state.email}
                onChange={this.handleChange}
                info='Use an email connected with a gravatar image.'
                error={errors.email}
              />
              <TextInputField
                name='password'
                type='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handleChange}
                error={errors.password}
              />
              <TextInputField
                name='password2'
                type='password'
                placeholder='Confirm password'
                value={this.state.password2}
                onChange={this.handleChange}
                error={errors.password2}
              />

              <button className='btn btn-auth'>Sign Up</button>

              <div>
                <small>
                  Have already account ?{' '}
                  <Link
                    className='link'
                    to='/admin/signin'
                  >
                    SIGN IN
                  </Link>
                </small>
              </div>
            </form>
            <small className='terms-conditions'>
              By signing up, you confirm that you have read and accepted our
              <Link
                to='/privacy-policy'
                className='link'
              >
                Terms & conditions
              </Link>
              and{' '}
              <Link
                to='/privacy-policy'
                className='link'
              >
                Privacy Policy
              </Link>
            </small>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps, { signUp })(withRouter(SignUp))
