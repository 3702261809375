import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import Editor from '../shared/Editor'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      {
        font: [
          'Roboto',
          'Lora',
          'Lato',
          'Nunito',
          'Montserrat',
          'Poppins',
          'Raleway'
        ]
      }
    ],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video'],
    [{ script: 'sub' }, { script: 'super' }],
    ['align', { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['clean']
  ]
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
]


const AddBlog = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    createdAt: '',
    image: ''
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(initialState)
  const [description, setDescription] = useState('')
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type === 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    const url = '/api/v1.0.0/blogs'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }
    data.append('description', description)

    try {
      await axios({
        method: 'post',
        url: url,
        data
      })
    } catch (error) {
      setErrors(error.response.data)
    }
    props.fetchData()
    window.location.href = '/economies'
  }

  const { title, subtitle, createdAt } = errors

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className='row'>
        <TextInputField
          label='Blog Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />

        <TextInputField
          label='Blog Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='News Subtitle'
          error={subtitle}
        />
        <TextInputField
          label='Published on'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={(e) => {
            setFormData({ ...formData, description: e.target.value })
          }}
          error={createdAt}
        />
      </div>

      {/* <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Your blog post content goes here ...'
        error={description}
      /> */}
      <ReactQuill
        theme='snow'
        value={description}
        name='content'
        onChange={setDescription}
        modules={modules}
        className='editor-container'
      />

      <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
        />
      </div>

      <button className='btn' type='submit' id='submit-button'>
        {props.title}
      </button>
    </form>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddBlog
