import React from 'react'
import { NavLink } from 'react-router-dom'
import Blog from './Blog'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Blogs = (props) => {
  let renderContent
  if (props.blogs.length) {
    renderContent = props.blogs.map((blog, i) => (
      <div
        key={blog._id}
        className='cards'
      >
        <NavLink to={`/blogs/${blog._id}`}>
          <Blog blog={blog} />
        </NavLink>
      </div>
    ))
  } else if (props.blogs.length === 0) {
    renderContent = <div>No blogs found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Blogs: ' + props.blogs.length}>
      <div className='main-wrapper'>
        <div className='cards'>{renderContent}</div>
      </div>
    </Layout>
  )
}

Blogs.propTypes = {}

export default Blogs
