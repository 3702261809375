import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

// import './assets/styles/normalize.css'
import './assets/styles/code-highlighter.scss'
import './assets/styles/common.css'
import './assets/styles/header.scss'
import './assets/styles/footer.scss'
import './assets/styles/about.css'
import './assets/styles/buttons.css'
import './assets/styles/icons.css'
import './assets/styles/form.scss'
import './assets/styles/main.scss'
import './assets/styles/image-detail.scss'
import './assets/styles/support.scss'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector('.wrapper')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
