import React from 'react'
import axios from 'axios'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Members from './components/member/Members'
import MemberDetail from './components/member/MemberDetail'
import AddMember from './components/member/AddMember'
import EditMember from './components/member/EditMember'
import NewsList from './components/news/NewsList'
import NewsDetail from './components/news/NewsDetail'
import EditNews from './components/news/EditNews'
import Blogs from './components/blog/Blogs'
import BlogDetail from './components/blog/BlogDetail'
import EditBlog from './components/blog/EditBlog'

import Events from './components/event/Events'
import EventDetail from './components/event/EventDetail'
import EditEvent from './components/event/EditEvent'

import Supports from './components/support/Supports'
import SupportDetail from './components/support/SupportDetail'
import EditSupport from './components/support/EditSupport'

import Admin from './components/admin/Admin'
import ImageDetail from './components/gallery/ImageDetail'
import Images from './components/gallery/Images'
import EditImage from './components/gallery/EditImage'
import { Provider } from 'react-redux'
import PrivateRoute from './components/shared/PrivateRoute'
import store from './store/store'

import ActivationEmailMessage from './components/auth/ActivationEmailMessage'
import SignUp from './components/auth/SignUp'
import SignIn from './components/auth/SignIn'

import UserActivation from './components/auth/UserActivation'
import ResetPassword from './components/auth/ResetPassword'
import ForgotPassword from './components/auth/ForgotPassword'
import CheckYourEmail from './components/auth/CheckYourEmail'

import setAuthToken from './utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import { signOut, setCurrentUser } from './store/actions/authActions'
import Message from './components/Message'

// AWS, Google cloud, Azure, Heroku, Digital Ocean,

//check for token
if (localStorage.jwtToken) {
  //set auth token header auth
  setAuthToken(localStorage.jwtToken)
  //Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken)
  //set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))
  //check for expired token
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    //logout user
    axios
      .get('/api/users/signout')
      .then((res) => {
        console.log(res)
      })
      .catch((err) => console.log(err))
    store.dispatch(signOut())
    //Redirect to login;
    window.location.href = '/signin'
  }
}

class App extends React.Component {
  state = {
    members: [],
    blogs: [],
    newsList: [],
    events: [],
    supports: [],
    images: [],
    errors: {},
    user: {},
    users: [],
  }
  constructor(props) {
    super(props)
    const url = window.location.origin
    if (!url.includes('localhost') && !url.includes('https')) {
      window.location = `https:${url.split(':')[1]}`
    }
  }
  componentDidMount() {
    this.fetchData()
    this.fetchBlogs()
    this.fetchNews()
    this.fetchEvents()
    this.fetchSupports()
    this.fetchImages()
    // this.fetchUsers()
  }

  fetchBlogs = () => {
    const url = '/api/v1.0.0/blogs'
    axios
      .get(url)
      .then((response) => {
        this.setState({ blogs: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchEvents = () => {
    const url = '/api/v1.0.0/events'
    axios
      .get(url)
      .then((response) => {
        this.setState({ events: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
    fetchSupports = () => {
    const url = '/api/v1.0.0/supports'
    axios
      .get(url)
      .then((response) => {
        this.setState({ supports: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  fetchNews = () => {
    const url = '/api/v1.0.0/news'
    axios
      .get(url)
      .then((response) => {
        this.setState({ newsList: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }
  // fetchUsers = async () => {
  //   const url = '/api/v1.0.0/users'
  //   axios
  //     .get(url)
  //     .then((response) => {
  //       this.setState({ users: response.data })
  //     })
  //     .catch((err) => {
  //       this.setState({ errors: err.response.data })
  //     })
  // }

  fetchImages = () => {
    const url = '/api/v1.0.0/images'
    axios
      .get(url)
      .then((response) => {
        this.setState({ images: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }

  fetchData = () => {
    const url = '/api/v1.0.0/members'
    axios
      .get(url)
      .then((response) => {
        this.setState({ members: response.data })
      })
      .catch((err) => {
        this.setState({ errors: err.response.data })
      })
  }

  render() {
    const { members } = this.state
   

    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path='/about' component={About} />
            <Route
              exact
              path='/members/add'
              component={(props) => (
                <AddMember fetchData={this.fetchData} {...props} />
              )}
            />
            <PrivateRoute
              exact
              path='/members/edit/:id'
              component={(props) => (
                <EditMember {...props} fetchData={this.fetchData} />
              )}
            />

            <Route
              exact
              path='/members/:id'
              component={(props) => (
                <MemberDetail
                  {...props}
                  deleteStudent={this.deleteStudent}
                  fetchData={this.fetchData}
                  members={members}
                />
              )}
            />
            <PrivateRoute
              exact
              path='/members'
              component={(props) => (
                <Members
                  {...props}
                  members={members}
                  fetchData={this.fetchData}
                />
              )}
            />

            <PrivateRoute
              exact
              path='/images/edit/:id'
              component={(props) => (
                <EditImage
                  {...props}
                  events={this.state.images}
                  fetchData={this.fetchImages}
                />
              )}
            />

            <Route
              exact
              path='/images'
              component={(props) => (
                <Images
                  {...props}
                  images={this.state.images}
                  fetchData={this.fetchImages}
                />
              )}
            />
            <Route
              exact
              path='/images/:id'
              component={(props) => (
                <ImageDetail
                  {...props}
                  images={this.state.images}
                  fetchData={this.fetchImages}
                />
              )}
            />

            <PrivateRoute
              exact
              path='/blogs/edit/:id'
              component={(props) => (
                <EditBlog
                  {...props}
                  blogs={this.state.blogs}
                  fetchData={this.fetchBlogs}
                />
              )}
            />
            <Route
              exact
              path='/blogs/:id'
              component={(props) => (
                <BlogDetail
                  {...props}
                  blogs={this.state.blogs}
                  fetchData={this.fetchBlogs}
                />
              )}
            />
            <Route
              exact
              path='/blogs'
              component={(props) => (
                <Blogs
                  {...props}
                  blogs={this.state.blogs}
                  fetchData={this.fetchBlogs}
                />
              )}
            />

            <Route
              exact
              path='/news'
              component={(props) => (
                <NewsList
                  {...props}
                  newsList={this.state.newsList}
                  fetchData={this.fetchNews}
                />
              )}
            />
            <Route
              exact
              path='/news/:id'
              component={(props) => (
                <NewsDetail
                  {...props}
                  newsList={this.state.newsList}
                  fetchData={this.fetchNews}
                />
              )}
            />
            <PrivateRoute
              exact
              path='/news/edit/:id'
              component={(props) => (
                <EditNews
                  {...props}
                  newsList={this.state.newsList}
                  fetchData={this.fetchNews}
                />
              )}
            />
            <Route
              exact
              path='/events'
              component={(props) => (
                <Events
                  {...props}
                  events={this.state.events}
                  fetchData={this.fetchEvents}
                />
              )}
            />
            <Route
              exact
              path='/events/:id'
              component={(props) => (
                <EventDetail
                  {...props}
                  events={this.state.events}
                  fetchData={this.fetchEvents}
                />
              )}
            />
            <PrivateRoute
              exact
              path='/events/edit/:id'
              component={(props) => (
                <EditEvent
                  {...props}
                  events={this.state.events}
                  fetchData={this.fetchEvents}
                />
              )}
            />
            {
              /**support */
            }
             <Route
              exact
              path='/supports'
              component={(props) => (
                <Supports
                  {...props}
                  supports={this.state.supports}
                  fetchData={this.fetchSupports}
                />
              )}
            />
            <Route
              exact
              path='/supports/:id'
              component={(props) => (
                <SupportDetail
                  {...props}
                  supports={this.state.supports}
                  fetchData={this.fetchSupports}
                />
              )}
            />
            <PrivateRoute
              exact
              path='/supports/edit/:id'
              component={(props) => (
                <EditSupport
                  {...props}
                 supports={this.state.supports}
                  fetchData={this.fetchSupports}
                />
              )}
            />

            <PrivateRoute
              exact
              path='/admin/dashboard'
              component={(props) => (
                <Admin
                  fetchData={this.fetchData}
                  fetchBlogs={this.fetchBlogs}
                  fetchEvents={this.fetchEvents}
                  fetchImages={this.fetchImages}
                  fetchNews={this.fetchNews}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path='/user/activation/:id'
              component={(props) => <UserActivation {...props} />}
            />
            <Route
              exact
              path='/admin/signup'
              component={(props) => <SignUp />}
            />
            <Route
              exact
              path='/admin/signin'
              component={(props) => <SignIn title='Sign in to your account' />}
            />
            <Route
              exact
              path='/forgotpassword'
              component={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path='/resetpassword/:id'
              component={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path='/activation-message'
              component={(props) => <ActivationEmailMessage {...props} />}
            />
            {/* <PrivateRoute exact path='/users' component={Users} /> */}

            <Route
              exact
              path='/check-email'
              component={(props) => <CheckYourEmail {...props} />}
            />
            <Route
              exact
              path='/message'
              component={(props) => <Message {...props} />}
            />
            <Route exact path='/' 
                 supports={this.state.supports} fetchSupports = {this.fetchSupports} component={Home}  />
          </Switch>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App
