import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import { countries } from '../../utils/countries'

const AddImage = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    description: '',
    createdAt: '',
    location: '',
    image: '',
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(initialState)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    const url = '/api/v1.0.0/images'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: 'post',
        url: url,
        data,
      })
    } catch (error) {
      setErrors(error.response.data)
    }

    props.fetchData()
    window.location.href = '/images'
  }

  const { title, subtitle, createdAt, description, location } = errors

  return (
    <form
      onSubmit={onSubmit}
      noValidate
    >
      <div className='row'>
        <TextInputField
          label='Image Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Image Title'
          error={title}
        />

        <TextInputField
          label='Image Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='Image Subtitle'
          error={subtitle}
        />
        <TextInputField
          label='Published on'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={onChange}
          error={createdAt}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Location'
          id='location'
          type='text'
          name='location'
          value={formData.location}
          onChange={onChange}
          placeholder='Address...'
          error={location}
        />
      </div>

      <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Information about the image ...'
        error={description}
      />

      <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
        />
      </div>

      <button
        className='btn'
        type='submit'
        id='submit-button'
      >
        {props.title}
      </button>
    </form>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddImage
