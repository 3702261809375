import React from 'react'
import { NavLink } from 'react-router-dom'
import News from './News'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'

const NewsList = (props) => {
  let renderContent
  if (props.newsList.length) {
    renderContent = props.newsList.map((news, i) => (
      <div
        key={news._id}
        className='cards'
      >
        <NavLink
          to={`/news/${news._id}`}
          key={news._id}
        >
          <News news={news} />
        </NavLink>
      </div>
    ))
  } else if (props.newsList.length === 0) {
    renderContent = <div>No news found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'News: ' + props.newsList.length}>
      <div className='main-wrapper'>
        <div className='cards'>{renderContent}</div>
      </div>
    </Layout>
  )
}

NewsList.propTypes = {}

export default NewsList
