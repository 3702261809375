import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import moment from 'moment'

const EditImage = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    description: '',
    createdAt: '',
    location: '',
    image: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/images`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const student = response.data.find((st) => st._id == id)

        const { title, subtitle, createdAt, description, location } = student

        const data = {
          title,
          subtitle,
          createdAt,
          location,
          description,
        }
        setFormData(data)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/images/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const imageData = new FormData()
    for (const key in formData) {
      imageData.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data: imageData,
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/images/${id}`)
  }

  const { title, subtitle, createdAt, description, location } = errors
  const date = moment(formData.createdAt).format('YYYY-MM-DDTHH:mm:ss')

  return (
    <Layout title='Edit Image'>
      <form
        onSubmit={onSubmit}
        noValidate
      >
        <div className='row'>
          <TextInputField
            label='Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />

          <TextInputField
            label='Subtitle'
            id='subtitle'
            type='text'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='Subtitle'
            error={subtitle}
          />
          <TextInputField
            label='Published on'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={date}
            onChange={onChange}
            error={createdAt}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='Location'
            id='location'
            type='text'
            name='location'
            value={formData.location}
            onChange={onChange}
            placeholder='Address or location'
            error={location}
          />
        </div>

        <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Image description goes here ...'
          error={description}
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
          />
        </div>

        <button
          className='btn'
          type='submit'
          id='submit-button'
        >
          Update
        </button>
      </form>
    </Layout>
  )
}

EditImage.propTypes = {}

export default EditImage
