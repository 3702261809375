import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countries } from '../../utils/countries'
import moment from 'moment'

// Select options for status
// Select options for status

let interval
/// Select options for status
const options = [
  { label: '* Select your title', value: '' },
  { label: 'Dr.', value: 'Dr.' },
  { label: 'Professor', value: 'Professor' },
  { label: 'Mr.', value: 'Mr' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Mr.', value: 'Mr.' },
]
const genders = [
  { label: '* Gender', value: '' },
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Other', value: 'Other' },
]
const batches = [
  { label: '* Select yout batch', value: '' },
  { label: 'December-2017', value: 'December-2018' },
  { label: 'April-2018', value: 'April-2018' },
  { label: 'August-2018', value: 'August-2018' },
  { label: 'January-2019', value: 'January-2019' },
  { label: 'Other', value: 'Other' },
]

const trainings = [
  { label: '* Select program', value: '' },
  { label: '9 Months Web Development', value: '9 Months Web Development' },
  { label: '6 Months Web Development', value: '6 Months Web Development' },
  { label: '3 Months Web Development', value: '3 Months Web Development' },
  { label: '6 Months Fullstack', value: '6 Months Fullstack' },
  { label: '3 Months Fullstack', value: '3 Months Fullstack' },
  { label: '6 Months Data Analysis', value: '9 Months Data Analysis' },
  { label: '6 Months Mobile App Dev', value: '6 Months Mobile App Dev' },
  { label: 'React Training', value: 'React Training' },
  { label: 'JavaScript Training', value: 'JavaScript Training' },
  { label: 'Python Training', value: 'Python Training' },
  {
    label: '30DaysOfPython Certification Exam',
    value: '30DaysOfPython Certification Exam',
  },
  {
    label: '30DaysOfJavaScript Certification Exam',
    value: '30DaysOfJavaScript Certification Exam',
  },
  { label: 'HTML and CSS', value: 'HTML and CSS' },
  { label: 'Other', value: 'Other' },
]

const EditStudent = (props) => {
  const initialState = {
    firstName: '',
    lastName: '',
    title: '',
    gender: '',
    dateOfBirth: '',
    tel: '',
    email: '',
    city: '',
    zipcode: '',
    streetName: '',
    country: '',
    nationality: '',
    motivation: '',
    imageUrl: '',
  }
  const [formData, setFormData] = useState(initialState)

  useEffect(() => {
    const url = `/api/v1.0.0/members`
    axios.get(url).then((response) => {
      const id = props.match.params.id
      const student = response.data.find((st) => st._id == id)
      const {
        firstName,
        lastName,
        title,
        gender,
        dateOfBirth,
        tel,
        email,
        city,
        zipcode,
        streetName,
        country,
        nationality,
        motivation,
        imageUrl,
      } = student

      const data = {
        firstName,
        lastName,
        title,
        gender,
        dateOfBirth,
        tel,
        email,
        country,
        city,
        zipcode,
        streetName,
        nationality,
        motivation,
        imageUrl,
      }
      setFormData(data)
    })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id

    const url = `/api/v1.0.0/members/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const imageData = new FormData()
    for (const key in formData) {
      imageData.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data: imageData,
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/members/${id}`)
  }

  const date = moment(formData.dateOfBirth).format('YYYY-MM-DD')

  return (
    <Layout title='Edit Member'>
      <form onSubmit={onSubmit}>
        <div className='row'>
          <TextInputField
            label='First Name'
            id='firstName'
            type='text'
            name='firstName'
            value={formData.firstName}
            onChange={onChange}
            placeholder='First name'
          />

          <TextInputField
            label='Last Name'
            id='lastName'
            type='text'
            name='lastName'
            value={formData.lastName}
            onChange={onChange}
            placeholder='Last name'
          />
          <SelectListGroup
            label='Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            options={options}
          />
        </div>
        <div className='row'>
          <SelectListGroup
            label='Gender'
            id='gender'
            name='gender'
            value={formData.gender}
            onChange={onChange}
            options={genders}
          />

          <TextInputField
            label='Date of Birth'
            type='date'
            id='dateOfBirth'
            name='dateOfBirth'
            value={date}
            onChange={onChange}
            autoComplete={false}
          />
          <TextInputField
            label='Tel.'
            id='tel'
            name='tel'
            value={formData.tel}
            onChange={onChange}
            placeholder='Tel.+358'
          />
        </div>
        <div className='row'>
          <TextInputField
            label='Email'
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={onChange}
            placeholder='Your Email'
          />

          <SelectListGroup
            label='Country of Origin'
            id='country'
            name='country'
            value={formData.country}
            onChange={onChange}
            options={countries}
          />

          <SelectListGroup
            label='Nationality'
            id='nationality'
            name='nationality'
            value={formData.nationality}
            onChange={onChange}
            options={countries}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='City'
            id='city'
            type='text'
            name='city'
            value={formData.city}
            onChange={onChange}
            placeholder='City'
          />
          <TextInputField
            label='Zip Code'
            id='zipcode'
            type='text'
            name='zipcode'
            value={formData.zipcode}
            onChange={onChange}
            placeholder='Zipe Code'
          />
          <TextInputField
            label='Street Name and Number'
            id='streetName'
            type='text'
            name='streetName'
            value={formData.streetName}
            onChange={onChange}
            placeholder='Street Name'
          />
        </div>
        <hr />

        <TextAreaField
          label='Your comment'
          id='motivation'
          name='motivation'
          cols='120'
          rows='15'
          value={formData.motivation}
          onChange={onChange}
          placeholder='Motivation'
        />
        <div className='form-group'>
          <input
            type='file'
            name='imageUrl'
            onChange={onChange}
          />
        </div>

        <button
          className='btn'
          type='submit'
          id='submit-button'
        >
          Update
        </button>
      </form>
    </Layout>
  )
}

EditStudent.propTypes = {}

export default EditStudent
