import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import Editor from '../shared/Editor'

const AddEvent = (props) => {
  const intitalState = {
    title: '',
    subtitle: '',
    country: '',
    city: '',
    streetName: '',
    createdAt: '',
    image: '',
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(intitalState)
  const [description, setDescription] = useState('')
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type === 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const url = '/api/v1.0.0/events'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: 'post',
        url: url,
        data,
      })
    } catch (error) {
      setErrors(error.response.data)
    }

    props.fetchData()
    window.location.href = '/events'
  }

  const {
    title,
    subtitle,
    country,
    streetName,
    createdAt,
    city,
    image,
  } = errors

  return (
    <form
      onSubmit={onSubmit}
      noValidate
    >
      <div className='row'>
        <TextInputField
          label='Event Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />
        <TextInputField
          label='Event Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='News Subtitle'
          error={subtitle}
        />
        <TextInputField
          label='Event Time'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={onChange}
          error={createdAt}
        />
      </div>

      <div className='row'>
        <TextInputField
          label='Country'
          id='country'
          type='text'
          name='country'
          value={formData.country}
          onChange={onChange}
          placeholder='Country'
          error={country}
        />
        <TextInputField
          label='City'
          id='city'
          type='text'
          name='city'
          value={formData.city}
          onChange={onChange}
          placeholder='City'
          error={city}
        />
        <TextInputField
          label='Street Name'
          id='streetName'
          type='text'
          name='streetName'
          value={formData.streetName}
          onChange={onChange}
          placeholder='Event streetName'
          error={streetName}
        />
      </div>

  {/*     <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Your post  goes here ...'
        error={description}
      /> */}
      <Editor value={description} onChange={setDescription} />

      <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
        />
      </div>

      <button
        className='btn'
        type='submit'
        id='submit-button'
      >
        {props.title}
      </button>
    </form>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddEvent
