import React, { useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

const Ul = styled.ul`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  list-style: none;
  z-index: 20;
  li {
    padding: 15px 10px;
  }
  .active {
    width: 100%;
    background-color: #00e091;
    padding: 10px 15px;
    border-radius: 2px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #00e091;
    background-color: #00e091;
    background: #f1ebf1;
    // background-image: radial-gradient(
    //   circle at 50% top,
    //   rgb(252, 252, 20) 15%,
    //   rgba(84, 90, 182, 0) 75%
    // );

    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    margin: 0;
    li {
      color: #fff;
    }
    a {
      font-size: 0.75rem !important;
    }
  }
`

const RightNav = ({ open, auth, handleSignOut }) => {
  const { isAuthenticated, user } = auth
  return (
    <Ul open={open}>
      <li>
        <NavLink
          exact
          activeClassName='active'
          to='/'
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName='active'
          to='/about'
        >
          About
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName='active'
          to='/blogs'
        >
          Blogs
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName='active'
          to='/news'
        >
          News
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName='active'
          to='/images'
        >
          Gallery
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName='active'
          to='/supports'
        >
          Supports
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName='active'
          to='/events'
        >
          Events
        </NavLink>
      </li>
      {isAuthenticated && (
        <li>
          <NavLink
            exact
            activeClassName='active'
            to='/members'
          >
            members
          </NavLink>
        </li>
      )}
      {isAuthenticated && (
        <li>
          <NavLink
            exact
            activeClassName='active'
            to='/admin/dashboard'
          >
            Admin
          </NavLink>
        </li>
      )}

      {isAuthenticated ? (
        <li>
          <button
            className='nav-link btn btn-logout'
            to='/logout'
            onClick={handleSignOut}
          >
            Logout
          </button>
        </li>
      ) : (
        ''
      )}
    </Ul>
  )
}

const Nav = styled.nav`
  width: 100%;
  height: 150px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    font-size: 26px;
  }
  .brand-name {
    // background-color: #3b2f63;
    // color: white;
    border-radius: 2px;
    padding: 10px;
  }
`

const StyledBurger = styled.div`
  widht: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index:50;
  display:none;
  @media(max-width:768px){
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  justify-items: center;
 
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? 'red' : '#333333')};
    border-radius: 10px;
     transform-origin:1px;
     transition: all 0.3s linear;
     &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
     &:nth-child(2) {
      transform: ${({ open }) => (open ? 'rotate(100%)' : 'rotate(0)')};
      opacity:${({ open }) => (open ? 0 : 1)}
    }
     &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
`

const Burger = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <StyledBurger
        open={open}
        onClick={() => setOpen(!open)}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav
        open={open}
        auth={props.auth}
        handleSignOut={props.handleSignOut}
      />
    </>
  )
}
const Navbar = (props) => {
  const handleSignOut = (e) => {
    e.preventDefault()
    axios
      .get('/api/users/signout')
      .then((res) => {})
      .catch((err) => console.log(err))
    props.signOut()
    window.location.href = '/admin/signin'
  }
  return (
    <Nav>
      <NavLink
        to='/'
        className='brand-name'
      >
        <div className='rectangles'>
          <div className='parallelogram rect-green'>A</div>
          <div className='parallelogram rect-yellow'>A</div>
          <div className='parallelogram rect-red'>F</div>
        </div>
        <div className='finnish-flag'>
          <small className='association-name'>
            Amhara Association of Finland
          </small>
        </div>
      </NavLink>
      <Burger
        auth={props.auth}
        handleSignOut={handleSignOut}
      />
    </Nav>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, { signOut })(Navbar)
