import React, { useState } from 'react'
import BlogStat from '../shared/BlogStat'
import PropTypes from 'prop-types'

const Support = (props) => {
  const images = props.support.images
  const index = Math.floor(Math.random() * images.length)
  if (Object.keys(props.support).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    display: 'block',
    padding: 0,
  }
  return (
    <>
      <div className='card-wrapper support'>
        <div className='card'>
          {images.length > 1 ? (
            <img
              src={images[index].imageUrl}
              style={imageStyles}
              loading='lazy'
              className='card-image'
              alt={props.support.title}
            />
          ) : (
            <i className='fas fa-user'></i>
          )}
          <div>
            <BlogStat item={props.support} />
            <div>
              <p>
                {props.support.description &&
                  props.support.description.slice(0, 150)}{' '}
                ...
              </p>
              <button className='read-more'>Read More</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Support
