import React from 'react'
import { NavLink } from 'react-router-dom'
import Event from './Event'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Events = (props) => {
  let renderContent
  if (props.events.length) {
    renderContent = props.events.map((event, i) => (
      <div
        key={event._id}
        className='cards'
      >
        <NavLink to={`/events/${event._id}`}>
          <Event event={event} />
        </NavLink>
      </div>
    ))
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Events: ' + props.events.length}>
      <div className='main-wrapper'>
        <div className='cards'>{renderContent}</div>
      </div>
    </Layout>
  )
}
Events.propTypes = {}

export default Events
