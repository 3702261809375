import React from 'react'
import PropTypes from 'prop-types'

const Contacts = (props) => (
  <div className='contacts'>
  <small>Amhara Association of Finland</small>
  <small> email: amhara.finland@gmail.com</small>
</div>
)

Contacts.propTypes = {}

export default Contacts
