import React from 'react'
import Layout from '../Layout'

const CheckYourEmail = (props) => {
  return (
    <Layout title='Password Reset Link'>
      <h1>A password reset link has been sen, check your email</h1>
    </Layout>
  )
}

export default CheckYourEmail
