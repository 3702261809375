import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Image from './Image'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Images = (props) => {
  let renderContent
  if (props.images.length) {
    renderContent = props.images.map((image, i) => (
      <NavLink
        to={`/images/${image._id}`}
        key={image._id}
      >
        <Image
          image={image}
          index={i}
        />
      </NavLink>
    ))
  } else if (props.images.length !== 0) {
    renderContent = <div>No image found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Images: ' + props.images.length}>
      <div className='main-wrappers'>
        <div className='gallery'>{renderContent}</div>
      </div>
    </Layout>
  )
}

Images.propTypes = {}

export default Images
