import React from 'react'
import { NavLink } from 'react-router-dom'
import SocialMedias from './social_media/social-media'
import Contacts from './Contacts'

const Footer = (props) => (
  <footer>
    <div className='footer-wrapper'>
      <h1 className='page-title'>Amhara Association of Finland &copy; 2020</h1>

      <div className='footer-menu'>
        <div>
          <h2>Menu</h2>
          <ul>
            <li>
              <NavLink
                exact
                activeClassName='active'
                to='/'
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName='active'
                to='/about'
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName='active'
                to='/blogs'
              >
                Blogs
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName='active'
                to='/news'
              >
                News
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName='active'
                to='/events'
              >
                Events
              </NavLink>
            </li>
          </ul>
        </div>

        <div>
          <h2>Social Media</h2>
          <SocialMedias />
        </div>
        <div>
          <h2>Contacts</h2>
          <Contacts />
        </div>
      </div>
      <div className='arrow'>
        <a href='#root'>
          <i className='fas fa-arrow-alt-circle-up'></i>
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
