import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import galleryImage from '../../assets/images/gallery_2.png'
import { connect } from 'react-redux'
import SocialShareButton from '../shared/SocialShareButton'

const BankAccount = ({ support }) => (
  <div className='support-detail'>
    <div>
      <p className='description'>{support.description}</p>
    </div>
    {/* <div className='bank-account-box description'>
      <h2>Account Details</h2>
      <small>Bank Name: {support.bankName}</small>
      <small>Account Name: {support.accountName}</small>
      <small>Account Number: {support.accountNumber}</small>
      <small>SWIFT/BIC Code: {support.swiftCode}</small>
      <small>Organizer: {support.organization} Team</small>
      <small>Responsible Person: {support.organizerName}</small>
    </div> */}
  </div>
)

const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({
  support,
  auth: { isAuthenticated },
  deleteSupport,
  deleteSupportImage,
  changeSupportAccess,
  id,
}) => {
  const [imageIndex, setImageIndex] = useState(0)
  const images = support.images

  let lock = support.public ? 'fas fa-lock-open' : 'fas fa-lock'
  const nextImage = () => {
    if (imageIndex < images.length) {
      setImageIndex(imageIndex + 1)
    } else if (imageIndex === images.length && imageIndex > 0) {
      setImageIndex(imageIndex - 1)
    }
  }
  const previousImage = () => {
    if (imageIndex < images.length && imageIndex !== 0) {
      let previousIndex = imageIndex - 1
      setImageIndex(previousIndex)
    }
  }

  useEffect(() => {
    if (!isAuthenticated && !support.public) {
      window.location = '/supports'
    }
    if (localStorage.getItem('randomId') === 'randomIdExist') {
    } else {
      const url = `/api/v1.0.0/supports/${support._id}`
      axios.get(url).then((response) => {
        localStorage.setItem('randomId', 'randomIdExist')
      })
    }
  }, [support])

  return (
    <div className='support-detail-wrapper'>
      <div style={{ textAlign: 'center' }}>
        <h4 className='card-title'>{support.title}</h4>
        <small className='card-subtitle'>{support.subtitle}</small>
        <small style={{ color: 'black', fontWeight: 'bold', display: 'block' }}>
          Fundraising Permit(Keräysnumero RA/2021/479)
        </small>
        <small className='card-date'>
          Posted <i className='far fa-clock'></i>{' '}
          {moment(support.createdAt).format('ll')}
        </small>
        <small className='card-date'>
          Opened on: {moment(support.startAt).format('ll HH:mm')}
        </small>
        <small className='card-date'>
          Closes on: {moment(support.endAt).format('ll HH:mm')}
        </small>
        <small style={{ color: 'red' }}>CLOSED</small>
        {/* <small><i class="fas fa-eye"></i> {support.views.length}</small> */}
      </div>
      <div className='gallery-image-full-size'>
        {images.length > 1 && images[imageIndex] ? (
          <>
            {imageIndex > 0 && (
              <div className='gallery-arrow order-2'>
                <i
                  className='fas fa-chevron-circle-left'
                  onClick={previousImage}
                ></i>
              </div>
            )}
            <div className='single-img-full-support order-1'>
              <div className='image-count'>
                {imageIndex + 1}/{images.length}
              </div>
              {isAuthenticated && (
                <div className='icons'>
                  <i
                    onClick={() =>
                      deleteSupportImage(images[imageIndex].imageId)
                    }
                    className={`btn-danger far fa-trash-alt`}
                    style={{ color: 'red', cursor: 'pointer' }}
                  ></i>
                  <i
                    onClick={() => changeSupportAccess(support._id)}
                    className={`btn-danger ${lock}`}
                    style={{ color: 'red', cursor: 'pointer' }}
                  ></i>
                </div>
              )}
              <img
                src={images[imageIndex].imageUrl}
                style={imageStyles}
                className='blog-image'
                loading='lazy'
                alt={support.title}
              />
            </div>
          </>
        ) : (
          <div className='single-img-full order-1'>
            <img
              src={galleryImage}
              className=''
              alt={support.title}
            />{' '}
          </div>
        )}
        {imageIndex + 1 !== images.length && (
          <div className='gallery-arrow'>
            <i
              className='fas fa-chevron-circle-right'
              onClick={nextImage}
            ></i>
          </div>
        )}
      </div>
      <BankAccount support={support} />

      <div className='card-footer'>
        <div>
          <small className='card-date'>
            <i className='far fa-clock'></i> Published on{' '}
            {moment(support.createdAt).format('ll')}{' '}
          </small>
        </div>
        <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton
            category={'supports'}
            id={support._id}
          />
        </div>
        {isAuthenticated && (
          <div className='icons'>
            <NavLink
              to={`/supports/edit/${support._id}`}
              className='btn-primary'
            >
              <i className='far fa-edit'></i>
            </NavLink>
            <i
              className='btn-danger far fa-trash-alt'
              onClick={deleteSupport}
            ></i>
          </div>
        )}
      </div>
    </div>
  )
}

const SupportDetail = (props) => {
  const { auth } = props
  const deleteSupport = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/supports/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/supports')
  }

  const deleteSupportImage = (imageId) => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/supports/${id}/image/${imageId}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
  }
  const changeSupportAccess = (id) => {
    const url = `/api/v1.0.0/supports/${id}/access`
    axios.put(url).then((response) => {
      return
    })
    props.fetchData()
  }

  let renderContent

  if (props.supports.length > 0) {
    const support = props.supports.find(
      (supt) => supt._id === props.match.params.id
    )
    renderContent = (
      <Details
        support={support}
        auth={auth}
        deleteSupport={deleteSupport}
        deleteSupportImage={deleteSupportImage}
        changeSupportAccess={changeSupportAccess}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout>
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(SupportDetail)
