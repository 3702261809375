import React from 'react'
import { NavLink } from 'react-router-dom'
import Member from './Member'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'

const Members = (props) => {
  let renderContent
  if (props.members.length) {
    renderContent = props.members.map((member, i) => (
      <div
        key={member._id}
        className='students'
      >
        <NavLink to={`/members/${member._id}`}>
          <Member member={member} />
        </NavLink>
      </div>
    ))
  } else if (props.members.length === 0) {
    renderContent = <div>No members found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title={'Members: ' + props.members.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </Layout>
  )
}

Members.propTypes = {}

export default Members
