import React from 'react'
import PropTypes from 'prop-types'
import BlogStat from '../shared/BlogStat'

const News = (props) => {
  if (Object.keys(props.news).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    width: '30%',
    // borderRadius: '2px',
    display: 'block',
    padding: 0,
  }

  return (
    <div className="card-wrapper">
      <div className='card'>
      {props.news.imageUrl.length > 1 ? (
        <img
          src={props.news.imageUrl}
          style={imageStyles}
          loading='lazy'
          className='event-card-image'
          alt={props.news.title}
        />
      ) : (
        <i className='fas fa-user'></i>
      )}
      <div>
        <BlogStat
          item={props.news}
          format='short'
        />
        <div>
        <p
        dangerouslySetInnerHTML={{ __html: props.news.description && `${props.news.description.slice(0, 250)} ...`}}
        className=''
      /> 
     
        </div>
      </div>
    </div>
    </div>
  )
}

News.propTypes = {}

export default News
