import React from 'react'
import moment from 'moment'

const BlogStat = ({ format, item: { title, subtitle, createdAt } }) => {
  let postedOn =
    format == 'long' ? (
      <small className='card-date'>
        {' '}
        Posted on <i className='far fa-clock'></i>{' '}
        {moment(createdAt).format('ll')}
      </small>
    ) : (
      <small className='card-date'>
        {' '}
        Posted <i className='far fa-clock'></i> {moment(createdAt).fromNow()}
      </small>
    )
  return (
    <>
      <h2 className='card-title'>{title}</h2>
      <small className='card-subtitle'>{subtitle} </small>
      {postedOn}
    </>
  )
}
BlogStat.defaultProps = {
  format: 'short',
}
export default BlogStat
