import React from 'react'
import Layout from './Layout'

const styles = {
  margin: 'auto 15px',
}
const Message = (props) => (
  <Layout>
    <div style={styles}>
      <h1>Thank you for applying for Amhara Association membership</h1>
      <p>
        We have received your membership application, after processing it we
        will send your membership approval email.{' '}
      </p>
    </div>
  </Layout>
)

export default Message
