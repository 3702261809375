import React from 'react'
import PropTypes from 'prop-types'
import { SocialIcon } from 'react-social-icons'
import socialStyles from './social-media.module.scss'

const socials = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com',
    icon: 'fa-github-square',
    brandColor: '#4267B2',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com',
    icon: 'fa-twitter-square',
    brandColor: '#00b9ff',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com',
    icon: 'fa-linkedin',
    brandColor: '#0085d2',
  },
]

const SocialMedias = (props) => {
  const socialsList = socials.map((social) => (
    <div key={social.name}>
      <SocialIcon url={social.url} />
    </div>
  ))
  return <div className={socialStyles.socialsWrapper}>{socialsList}</div>
}

SocialMedias.propTypes = {}

export default SocialMedias
