import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'

const imageStyles = {
  borderRadius: '50%',
  marginTop: 25,
  // minWidth: '%',
  // maxWidth: '100%',
  // display: 'block',
}

const Details = ({
  member,
  sendInvitation,
  acceptInvitation,
  deleteStudent,
  id,
}) => (
  <div className='single-student'>
    <div className='profile-header'>
      {member.imageUrl.length > 1 ? (
        <img
          src={member.imageUrl}
          style={imageStyles}
          className='card-image'
          loading='lazy'
          alt={member.firstName}
        />
      ) : (
        <i className='fas fa-user'></i>
      )}
      <div
        className='profile-category'
        style={{ textAlign: 'center' }}
      >
        <h4>
          {member.firstName} {member.lastName}{' '}
          {member.accepted ? <i className='fas fa-check-circle'></i> : <>😕</>}
        </h4>
        <small>{member.title}</small>
      </div>
      <div className='contacts'>
        {member.email && (
          <small>
            <i className='fas fa-envelope-square'></i> {member.email}{' '}
          </small>
        )}

        {member.tel && (
          <small>
            <i className='fas fa-phone-square-alt'></i> {member.tel}
          </small>
        )}
      </div>
      {/* <div className='student-status'>
        {member.invited && !member.confirmed && !member.accepted && (
          <small> 😕 Pending</small>
        )}
        {member.invited && member.confirmed && (
          <small>Coming for interview</small>
        )}
        {member.invited && member.confirmed && member.interviewed ? (
          <small>Interveiwed</small>
        ) : (
          ''
        )}
        {member.accepted || member.accepted ? (
          <small>
            {' '}
            <i className='fas fa-check-circle'></i> Accepted
          </small>
        ) : (
          ''
        )}{' '}
      </div>{' '} */}
      <div className='student-status'>
        {/* {(!member.invited || !member.accepted) && (
          <button onClick={sendInvitation} className='btn'>
            {' '}
            <i className='far fa-envelope'></i> Send Invitaton
          </button>
        )} */}

        {!member.accepted && (
          <button
            onClick={acceptInvitation}
            className='btn'
          >
            <i className='far fa-envelope'></i> Accept
          </button>
        )}

        {member.invited && !member.confirmed && !member.accepted && (
          <button
            onClick={'rejectApplicant'}
            className='btn reject-btn'
          >
            <i className='far fa-envelope'></i> Reject
          </button>
        )}
      </div>
    </div>
    <div className='profile-body'>
      <div className='profile-category'>
        <h4>Gender</h4>
        <small>{member.gender}</small>
      </div>
      <div className='profile-category'>
        <h4>Date of birth</h4>
        <small>{moment(member.dateOfBirth).format('DD/MM/YYYY')}</small>
      </div>

      <div className='profile-category'>
        <h4>Countr of Residence</h4>
        <small>{member.country}</small>
      </div>
      <div className='profile-category'>
        <h4>Nationality:</h4>
        <small>{member.nationality}</small>
      </div>
      <div className='profile-category'>
        <h4>Postal Address:</h4>
        <small>
          {member.firstName} {member.lastName}
        </small>
        <br />
        {member.streetName && <small>{member.streetName}</small>} <br />
        {member.zipcode && <small>{member.zipcode} </small>}{' '}
        {member.city && <small>{member.city.toUpperCase()}</small>}
      </div>

      {member.motivation && (
        <div className='profile-category'>
          <h4>Additional Message</h4>
          <p className='bio'>{member.motivation}</p>
        </div>
      )}
    </div>
    <div className='profile-footer'>
      <div>
        <small>
          <i className='far fa-clock'></i> Joined on{' '}
          {moment(member.createdAt).format('ll')}{' '}
        </small>
      </div>
      <div className='icons'>
        <NavLink
          to={`/members/edit/${member._id}`}
          className='btn-primary'
        >
          {' '}
          <i className='far fa-edit'></i>
        </NavLink>

        <i
          className='btn-danger far fa-trash-alt'
          onClick={deleteStudent}
        ></i>
      </div>
    </div>
  </div>
)

const MemberDetail = (props) => {
  const sendInvitation = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/members/invitation/${id}`
    axios.post(url).then((response) => {
      return
    })
    props.fetchData()
  }

  const acceptInvitation = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/members/invitation/accept/${id}`
    axios.post(url).then((response) => {
      return
    })
    props.fetchData()
  }
  const deleteStudent = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/members/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/members')
  }

  let renderContent

  if (props.members.length > 0) {
    const member = props.members.find((st) => st._id == props.match.params.id)
    renderContent = (
      <Details
        member={member}
        acceptInvitation={acceptInvitation}
        deleteStudent={deleteStudent}
        sendInvitation={sendInvitation}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout title='Member'>
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

MemberDetail.propTypes = {}

export default MemberDetail
