import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import moment from 'moment'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: ['Roboto', 'Lora','Lato', 'Nunito', 'Montserrat', 'Poppins', 'Raleway'] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    [{ 'script': 'sub'}, { 'script': 'super' }], 
    ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    ['clean'],
  ],
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]


const EditNews = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    createdAt: '',
    image: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
   fetchData()
   return () => {
    setFormData(initialState)
    setDescription('')
   }
  }, [])

  const fetchData = async () => {
    try {
      const url = `/api/v1.0.0/news`
      const {data} = await axios.get(url)

          const id = props.match.params.id
          const student = data.find((st) => st._id === id)
          console.log('one data: ', student)
          const {
            title,
            subtitle,
            createdAt,
          }  = student
          setFormData({title, subtitle, createdAt})
          setDescription(student.description)
    } catch (error) {
      setErrors(error.response.data)
    }
   
    
       

  }

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/news/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }
    data.append('description', description)
    axios({
      method: 'put',
      url: url,
      data: data,
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/news/${id}`)
  }

  const { title, subtitle, createdAt } = errors
  const date = moment(formData.createdAt).format('YYYY-MM-DDTHH:mm:ss')

  return (
    <Layout title='Edit News'>
      <form
        onSubmit={onSubmit}
        noValidate
      >
        <div className='row'>
          <TextInputField
            label='News Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />

          <TextInputField
            label='News Subtitle'
            id='subtitle'
            type='text'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='News Subtitle'
            error={subtitle}
          />
          <TextInputField
            label='Published on'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={date}
            onChange={onChange}
            error={createdAt}
          />
        </div>

        {/* <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your news post content goes here ...'
          error={description}
        /> */}
        <ReactQuill
          theme='snow'
          value={description}
          name='content'
          onChange={setDescription}
          modules={modules}
          className='editor-container'
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
          />
        </div>

        <button
          className='btn'
          type='submit'
          id='submit-button'
        >
          Update
        </button>
      </form>
    </Layout>
  )
}

EditNews.propTypes = {}

export default EditNews
