import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
   [
        {
          font: [
            'Roboto',
            'Lora',
            'Lato',
            'Nunito',
            'Montserrat',
            'Poppins',
            'Raleway'
          ]
        }
      ], 
      [{ color: [] }, { background: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      [{ script: 'sub' }, { script: 'super' }],
      ['align', { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['clean']
    ]
  }
  
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ]


const Editor = ({value, onChange}) => {
  return (
    <>
         <ReactQuill
          theme='snow'
          value={value}
          name='content'
          onChange={onChange}
          modules={modules}
          formats={formats}
          className='editor-container'
        />
    </>
  )
}



export default Editor