import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'

const AddSupport = (props) => {
  const intitalState = {
    title: '',
    subtitle: '',
    description: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    organization: '',
    organizerName: '',
    swiftCode: '',
    createdAt: '',
    startAt: '',
    endAt: '',
    files: [],
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(intitalState)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type == 'file') {
      setFormData({ ...formData, files: [...e.target.files] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const url = '/api/v1.0.0/supports'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      if (key === 'files') {
        formData[key].forEach((file) => data.append(`files[]`, file))
      }
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: 'post',
        url: url,
        data,
      })
    } catch (error) {
      console.log(errors)
      setErrors(error.response.data)
    }

    // props.fetchData()
    window.location.href = '/events'
  }

  const {
    title,
    subtitle,
    description,
    bankName,
    accountName,
    accountNumber,
    organization,
    organizerName,
    swiftCode,
    createdAt,
    startAt,
    endAt,
    images,
  } = errors
  return (
    <form
      onSubmit={onSubmit}
      noValidate
    >
      <div className='row col-2'>
        <TextInputField
          label='Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          error={title}
        />
        <TextInputField
          label='Subtitle'
          id='subtitle'
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='Subtitle'
          error={subtitle}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Organization'
          id='organization'
          type='text'
          name='organization'
          value={formData.organization}
          onChange={onChange}
          placeholder='Organization'
          error={organization}
        />
        <TextInputField
          label='Organizer Name'
          id='organizerName'
          type='text'
          name='organizerName'
          value={formData.organizerName}
          onChange={onChange}
          placeholder='Organizer Name'
          error={organizerName}
        />
        <TextInputField
          label='Bank Name'
          id='bankName'
          type='text'
          name='bankName'
          value={formData.bankName}
          onChange={onChange}
          placeholder='Bank Name'
          error={bankName}
        />
      </div>

      <div className='row'>
        <TextInputField
          label='Account Name'
          id='accountName'
          type='text'
          name='accountName'
          value={formData.accountName}
          onChange={onChange}
          placeholder='Account Name'
          error={accountName}
        />
        <TextInputField
          label='Account Number'
          id='accountNumber'
          type='text'
          name='accountNumber'
          value={formData.accountNumber}
          onChange={onChange}
          placeholder='Account Number'
          error={accountNumber}
        />
        <TextInputField
          label='SWIFT/BIC Code'
          id='swiftCode'
          type='text'
          name='swiftCode'
          value={formData.swiftCode}
          onChange={onChange}
          placeholder='SWIFT/BIC code'
          error={swiftCode}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Event Starts'
          type='datetime-local'
          id='startAt'
          name='startAt'
          value={formData.startAt}
          onChange={onChange}
          error={startAt}
        />
        <TextInputField
          label='Event Ends'
          type='datetime-local'
          id='endAt'
          name='endAt'
          value={formData.endAt}
          onChange={onChange}
          error={endAt}
        />
        <TextInputField
          label='Event Published'
          type='datetime-local'
          id='createdAt'
          name='createdAt'
          value={formData.createdAt}
          onChange={onChange}
          error={createdAt}
        />
      </div>

      <TextAreaField
        label='Description'
        id='description'
        name='description'
        cols='120'
        rows='15'
        value={formData.description}
        onChange={onChange}
        placeholder='Your post  goes here ...'
        error={description}
      />
      <div className='form-group file-upload'>
        <input
          type='file'
          name='files'
          id='files'
          onChange={onChange}
          placeholder='Upload an image'
          multiple
          accept='image/*'
          size='600'
        />
      </div>

      {/*
         <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
        />
      </div>
        */}

      <button
        className='btn'
        type='submit'
        id='submit-button'
      >
        {props.title}
      </button>
    </form>
  )
}

export default AddSupport
