import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Layout from '../Layout'
import moment from 'moment'
import { connect } from 'react-redux'
import SocialShareButton from '../shared/SocialShareButton'
const imageStyles = {
  minWidth: '100%',
  maxWidth: '100%',
  display: 'block',
}

const Details = ({ event, auth: { isAuthenticated }, deleteStudent, id }) => (
  <div className='card-detail'>
    <div className='card-header'>
      <div
        className='card-category'
        style={{ textAlign: 'center' }}
      >
        <h4 className='card-title'>{event.title}</h4>
        <small className='card-subtitle'>{event.subtitle}</small>
        <br />
        <small className='card-date'>
          Published on <i className='far fa-clock'></i>{' '}
          {moment(event.createdAt).format('ll')}
        </small>
        <small className='card-date'>
          Starts:
          <strong>{moment(event.time).format('ll HH:mm:ss')}</strong>
        </small>
      </div>
      {event.imageUrl.length > 1 ? (
        <img
          src={event.imageUrl}
          style={imageStyles}
          className='blog-image'
          loading='lazy'
          alt={event.title}
        />
      ) : (
        <i className='fas fa-user'></i>
      )}
    </div>
    <div className='card-body'>
      <div className='card-category'>
        <p className='description'>{event.description}</p>
      </div>
    </div>
    <div className='card-footer'>
      <div>
        <small className='card-date'>
          <i className='far fa-clock'></i> Published on{' '}
          {moment(event.createdAt).format('ll')}{' '}
        </small>
      </div>
      <div>
          <h3 style={{ color: 'black', fontSize: 36 }}>Share on</h3>
          <SocialShareButton
            category={'event'}
            id={event._id}
          />
        </div>
      {isAuthenticated && (
        <div className='icons'>
          <NavLink
            to={`/events/edit/${event._id}`}
            className='btn-primary'
          >
            {' '}
            <i className='far fa-edit'></i>
          </NavLink>

          <i
            className='btn-danger far fa-trash-alt'
            onClick={deleteStudent}
          ></i>
        </div>
      )}
    </div>
  </div>
)

const EventDetail = (props) => {
  const { auth } = props
  const deleteStudent = () => {
    const id = props.match.params.id
    const url = `/api/v1.0.0/events/${id}/delete`
    axios.delete(url).then((response) => {
      return
    })
    props.fetchData()
    props.history.push('/events')
  }

  let renderContent

  if (props.events.length > 0) {
    const event = props.events.find((evt) => evt._id == props.match.params.id)
    renderContent = (
      <Details
        event={event}
        auth={auth}
        deleteStudent={deleteStudent}
      />
    )
  } else {
    renderContent = <Spinner />
  }

  return (
    <Layout>
      <div className='main-wrapper'>{renderContent}</div>
    </Layout>
  )
}

EventDetail.propTypes = {}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(EventDetail)
