import React from 'react'
import Navbar from './navbar/Navbar'
import PropTypes from 'prop-types'

const Header = ({ title }) => (
  <header>
    <div className='header-wrapper'>
      <Navbar />
      {title && <h1 className='page-title'>{title}</h1>}
    </div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
}

export default Header
