import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AddMember from './AddMember'
import AddEvent from './AddEvent'
import AddBlog from './AddBlog'
import AddImage from './AddImage'
import AddSupport from './AddSupport'
import AddNews from './AddNews'
import SelectListGroup from '../shared/SelectListGroup'
import Navbar from './Navbar'
import Footer from '../Footer'

// Select options for status
const options = [
  { label: '* Select Category', value: '' },
  { label: 'Blog', value: 'Blog' },
  { label: 'Event', value: 'Event' },
  { label: 'Support', value: 'Support' },
  { label: 'Member', value: 'Member' },
  { label: 'News', value: 'News' },
  { label: 'Image', value: 'Image' },
  { label: 'Other', value: 'Other' },
]

const Admin = (props) => {
  const initialState = {
    category: 'Member',
    error: '',
  }
  const [formData, setFormData] = useState(initialState)
  const onChange = (e) => {
    setFormData({ ...formData, category: e.target.value })
  }
  const { error } = formData
  let renderForm
  if (formData.category === 'Member') {
    renderForm = (
      <AddMember
        title='Add Member'
        fetchData={props.fetchMember}
      />
    )
  } else if (formData.category === 'Event') {
    renderForm = (
      <AddEvent
        title='Add Event'
        fetchData={props.fetchEvents}
      />
    )
  } else if (formData.category === 'Blog') {
    renderForm = (
      <AddBlog
        title='Add Blog'
        fetchData={props.fetchBlogs}
      />
    )
  } else if (formData.category === 'News') {
    renderForm = (
      <AddNews
        title='Add News'
        fetchData={props.fetchNews}
      />
    )
  } else if (formData.category === 'Image') {
    renderForm = (
      <AddImage
        title='Add image'
        fetchData={props.fetchImages}
      />
    )
  } else if (formData.category === 'Support') {
    renderForm = (
      <AddSupport
        title='Add Support'
        fetchData={props.fetchSupports}
      />
    )
  } else {
  }
  return (
    <>
      <header>
        <div className='header-wrapper'>
          <Navbar />
          <h3 className='page-title'>Admin Page</h3>
        </div>
      </header>
      <main>
        <div className='main-wrapper'>
          <SelectListGroup
            id='category'
            name='category'
            value={formData.category}
            onChange={onChange}
            options={options}
            error={error}
          />
          {renderForm}
        </div>
      </main>
      <Footer />
    </>
  )
}

Admin.propTypes = {}

export default Admin
