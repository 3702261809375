import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countries } from '../../utils/countries'

/// Select options for status
const options = [
  { label: '* Select your title', value: '' },
  { label: 'Dr.', value: 'Dr.' },
  { label: 'Professor', value: 'Professor' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Mr.', value: 'Mr.' },
]
const genders = [
  { label: '* Gender', value: '' },
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Other', value: 'Other' },
]

const AddMember = (props) => {
  const intitalState = {
    firstName: '',
    lastName: '',
    title: '',
    gender: '',
    tel: '',
    city: '',
    zipcode: '',
    streetName: '',
    email: '',
    dateOfBirth: '',
    country: '',
    nationality: '',
    motivation: '',
    image: '',
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(intitalState)
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type === 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const url = '/api/v1.0.0/members'
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    axios({
      method: 'post',
      url: url,
      data,
    })
      .then((response) => {})
      .catch((err) => {
        setErrors(err.response.data)
      })
    props.fetchData()
    window.location.href = '/members'
  }

  const {
    firstName,
    lastName,
    title,
    gender,
    dateOfBirth,
    tel,
    email,
    country,
    nationality,
    city,
    zipcode,
    streetName,
    motivation,
  } = errors

  return (
    <form
      onSubmit={onSubmit}
      noValidate
    >
      <div className='row'>
        <TextInputField
          label='First Name'
          id='firstName'
          type='text'
          name='firstName'
          value={formData.firstName}
          onChange={onChange}
          placeholder='First name'
          error={firstName}
        />

        <TextInputField
          label='Last Name'
          id='lastName'
          type='text'
          name='lastName'
          value={formData.lastName}
          onChange={onChange}
          placeholder='Last name'
          error={lastName}
        />
        <SelectListGroup
          label='Title'
          id='title'
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
          options={options}
          error={title}
        />
      </div>
      <div className='row'>
        <SelectListGroup
          label='Gender'
          id='gender'
          name='gender'
          value={formData.gender}
          onChange={onChange}
          options={genders}
          error={gender}
        />

        <TextInputField
          label='Date of Birth'
          type='date'
          id='dateOfBirth'
          name='dateOfBirth'
          value={formData.dateOfBirth}
          onChange={onChange}
          placeholder='Date Of Birth'
          error={dateOfBirth}
        />

        <TextInputField
          label='Tel.'
          id='tel'
          name='tel'
          value={formData.tel}
          onChange={onChange}
          placeholder='Tel.+358'
          error={tel}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='Email'
          type='email'
          id='email'
          name='email'
          value={formData.email}
          onChange={onChange}
          placeholder='Your Email'
          error={email}
        />

        <SelectListGroup
          label='Country of Residence'
          id='country'
          name='country'
          value={formData.country}
          onChange={onChange}
          options={countries}
          error={country}
        />
        <SelectListGroup
          label='Nationality'
          id='nationality'
          name='nationality'
          value={formData.nationality}
          onChange={onChange}
          options={countries}
          error={nationality}
        />
      </div>
      <div className='row'>
        <TextInputField
          label='City'
          id='city'
          type='text'
          name='city'
          value={formData.city}
          onChange={onChange}
          placeholder='City'
          error={city}
        />
        <TextInputField
          label='Zip Code'
          id='zipecode'
          type='text'
          name='zipcode'
          value={formData.zipcode}
          onChange={onChange}
          placeholder='Zipe Code'
          error={zipcode}
        />
        <TextInputField
          label='Street Name and Number'
          id='streetName'
          type='text'
          name='streetName'
          value={formData.streetName}
          onChange={onChange}
          placeholder='Street Name'
          error={streetName}
        />
      </div>
      <hr />
      <TextAreaField
        label='Write Comment'
        id='motivation'
        name='motivation'
        cols='120'
        rows='15'
        value={formData.motivation}
        onChange={onChange}
        placeholder='Write additional message here ...'
        error={motivation}
      />
      <div className='form-group file-upload'>
        <input
          type='file'
          name='image'
          id='image'
          onChange={onChange}
          placeholder='Upload an image'
          accept='image/*'
          size='600'
          // style={{ display: 'none' }}
        />
        {/* <label htmlFor='image'>
            {' '}
            <i class='fas fa-upload'></i>
          </label> */}
      </div>
      <small>
        I hereby apply to be a member in Amhara Association of Finland and I
        certify that the above information provided is true. I abide by the
        rules and regulations of the organization. I also understand that it is
        non-political civic association and open for anyone who ascribes to the
        values, missions and objectives of the organization.
      </small>{' '}
      <br />
      <button
        className='btn'
        type='submit'
        id='submit-button'
      >
        {props.title}
      </button>
    </form>
  )
}

export default AddMember
