import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Layout from '../Layout'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import Editor from '../shared/Editor'
import moment from 'moment'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: ['Roboto', 'Lora','Lato', 'Nunito', 'Montserrat', 'Poppins', 'Raleway'] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    [{ 'script': 'sub'}, { 'script': 'super' }], 
    ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    ['clean'],
  ],
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]


const EditBlog = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    createdAt: '',
    image: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const url = `/api/v1.0.0/blogs`
    axios
      .get(url)
      .then((response) => {
        const id = props.match.params.id
        const student = response.data.find((st) => st._id == id)

        const { title, subtitle, createdAt} = student

        const data = {
          title,
          subtitle,
          createdAt
        }
        setFormData(data)
        setDescription(student.description)
      })
      .catch((err) => {
        setErrors(err.response.data)
      })
  }, [props])

  const onChange = (e) => {
    const { name, value, type } = e.target
    setFormData({ ...formData, [name]: value })
    if (type == 'file') {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const url = `/api/v1.0.0/blogs/${id}/edit`
    const config = {
      Accept: 'application/json',
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    const imageData = new FormData()
    for (const key in formData) {
      imageData.append(key, formData[key])
    }

    axios({
      method: 'put',
      url: url,
      data: imageData,
    }).then((response) => {})
    props.fetchData()
    props.history.push(`/blogs/${id}`)
  }

  const date = moment(formData.createdAt).format('YYYY-MM-DDTHH:mm:ss')
  const { title, subtitle, createdAt } = errors

  return (
    <Layout title='Edit Blog'>
      <form
        onSubmit={onSubmit}
        noValidate
      >
        <div className='row'>
          <TextInputField
            label='Blog Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            error={title}
          />

          <TextInputField
            label='Blog Subtitle'
            id='subtitle'
            type='text'
            name='subtitle'
            value={formData.subtitle}
            onChange={onChange}
            placeholder='Subtitle'
            error={subtitle}
          />
          <TextInputField
            label='Published on'
            type='datetime-local'
            id='createdAt'
            name='createdAt'
            value={date}
            onChange={onChange}
            error={createdAt}
          />
        </div>

      {/*   <TextAreaField
          label='Description'
          id='description'
          name='description'
          cols='120'
          rows='15'
          value={formData.description}
          onChange={onChange}
          placeholder='Your blog post content goes here ...'
          error={description}
        /> */}

        <ReactQuill
          theme='snow'
          value={description}
          name='content'
          onChange={setDescription}
          modules={modules}
          className='editor-container'
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
          />
        </div>

        <button
          className='btn'
          type='submit'
          id='submit-button'
        >
          Update
        </button>
      </form>
    </Layout>
  )
}

EditBlog.propTypes = {}

export default EditBlog
