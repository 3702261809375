import React from 'react'
import { Link } from 'react-router-dom'
import Layout from './Layout'
import PropTypes from 'prop-types'

const categories = [
  {
    category: 'Blogs',
    text: 'Click and go to the blogs section to read our blogs',
    link: '/blogs',
    icon: '<i class="fab fa-blogger-b"></i>',
  },
  {
    category: 'News',
    text: 'Click and go to the news section to read our news post',
    link: '/news',
    icon: '<i class="far fa-newspaper"></i>',
  },
  {
    category: 'Supports',
    text: 'Click and go to the fundraising page',
    link: 'supports',
    icon: '<i class="fas fa-hands-helping"></i>',
  },

  {
    category: 'Events',
    text: 'Click and go to the event to see our activities',
    link: 'events',
    icon: '<i class="fas fa-calendar-week"></i>',
  },
  // {
  //   category: 'Culture',
  //   text: 'Content is not available yet',
  //   link: '',
  //   icon: '<i class="fas fa-people-carry"></i>',
  // },
  // {
  //   category: 'History',
  //   text: 'Content is not available yet',
  //   link: '',
  //   icon: '<i class="fas fa-history"></i>',
  // },
  {
    category: 'Gallery',
    text: 'Click and go to gallery section',
    link: '/images',
    icon: '<i class="fas fa-images"></i>',
  },
  // {
  //   category: 'Videos',
  //   text: 'This section is not yet available',
  //   link: '/',
  //   icon: '<i class="fab fa-youtube"></i>',
  // },
  {
    category: 'Membership',
    text: 'Click and go to membership sectoin to apply for membership',
    link: 'members/add',
    icon: '<i class="fas fa-users"></i>',
  },
  // {
  //   category: 'Languages',
  //   text: 'The languages section is not yet available',
  //   link: '',
  //   icon: '<i class="fas fa-language"></i>',
  // },
  // {
  //   category: 'Projects',
  //   text: 'Projects section  is not yet available',
  //   link: '',
  //   icon: '<i class="fas fa-project-diagram"></i>',
  // },
  // {
  //   category: 'Online Resources',
  //   text: 'This section  is not yet available',
  //   link: '',
  //   icon: '<i class="fas fa-link"></i>',
  // },
]

const Home = (props) => {
  // const index = Math.floor(Math.random () * props.support.images.length)
  return (
    <Layout title='Amhara Association of Finland'>
      <div className='hero'>
        <p>
          Amhara Association of Finland is a legally registered non-profit
          association based in Helsinki, Finland
        </p>
      </div>
      <div className='categories'>
        {categories.map(({ category, text, link, icon }) => (
          <Link
            key={category}
            className={'category'}
            to={link ? link : '#'}
          >
            <div>
              {' '}
              <div dangerouslySetInnerHTML={{ __html: icon }}></div>
              <h1>{category}</h1>
              <small>{text}</small>
            </div>
          </Link>
        ))}
      </div>
      <Link
        className='apply-form-membership'
        to='/members/add'
      >
        Apply for Membership
      </Link>
    </Layout>
  )
}

Home.propTypes = {}

export default Home
