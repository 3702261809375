import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const Student = (props) => {
  if (Object.keys(props).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    borderRadius: '50%',
  }

  const renderStatus = (member) => {
    let status =
      (member.invited &&
        member.confirmed &&
        member.interviewed &&
        member.accepted) ||
      member.accepted ? (
        <small>
          {' '}
          <i className='fas fa-check-circle'></i>
        </small>
      ) : (
        !member.accepted && (
          <span
            role='img'
            aria-label='confused face emoji'
          >
            {' '}
            😕
          </span>
        )
      )

    return status
  }

  return (
    <>
      <div className='student'>
        <div>
          {props.member.imageUrl.length > 1 ? (
            <img
              src={props.member.imageUrl}
              style={imageStyles}
              loading='lazy'
              className='card-image'
              alt={props.member.firstName}
            />
          ) : (
            <i className='fas fa-user'></i>
          )}
          <div className='profile-category'>
            <h4>
              {props.member.firstName} {props.member.lastName}
              {renderStatus(props.member)}
            </h4>
          </div>

          <small>
            {props.member.city}, {props.member.country}
          </small>
        </div>

        <div>
          <small>
            <i className='far fa-clock'></i> Joined on{' '}
            {moment(props.member.createdAt).format('ll')}{' '}
          </small>
        </div>
      </div>
    </>
  )
}

Student.propTypes = {}

export default Student
