import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import TextInputField from '../shared/TextInputField'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import Layout from '../Layout'
class SignIn extends Component {
  state = {
    email: '',
    password: '',
    errors: {},
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/admin/dashboard')
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      })
    }
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/admin/dashboard')
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    const { email, password } = this.state
    const data = { email, password }
    this.props.signIn(data)
  }
  render() {
    const { errors } = this.state
    const { isAuthenticated } = this.props.auth
    // const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (isAuthenticated) {
      return <Redirect to='/admin/dashboard' />
    }
    return (
      <Layout title='Sign In'>
        <div className='auth-outer'>
          <div className='signin-form-wrapper'>
            <form onSubmit={this.handleSubmit}>
              <div>
                <h2 className='auth-title'>{this.props.title}</h2>
              </div>
              <TextInputField
                name='email'
                type='email'
                placeholder='Email'
                value={this.state.email}
                onChange={this.handleChange}
                error={errors.email}
              />
              <TextInputField
                name='password'
                type='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handleChange}
                error={errors.password}
              />

              <button
                type='submit'
                className='btn  btn-auth'
              >
                Sign In
              </button>

              <div>
                <small>
                  {' '}
                  Don't have an account ?
                  <Link
                    className='link'
                    to='/admin/signup'
                  >
                    Sign Up
                  </Link>
                </small>
              </div>
              <div>
                <small>
                  {' '}
                  <Link
                    className='link'
                    to='/forgotpassword'
                  >
                    Forgot password ?
                  </Link>
                </small>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, { signIn })(withRouter(SignIn))
